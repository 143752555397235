import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.setupTabs();
  }

  setupTabs() {
    document.querySelectorAll('.nav-link').forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        this.activateTab(link);
      });
    });
  }

  activateTab(link) {
    document.querySelectorAll('.nav-link').forEach((item) => item.classList.remove('active'));
    document.querySelectorAll('.tab-pane').forEach((item) => item.classList.remove('active'));

    link.classList.add('active');
    document.getElementById(link.dataset.tab).classList.add('active');
  }
}
