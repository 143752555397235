import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  getTurboStream(event) {
    event.preventDefault();
    fetch(event.currentTarget.href, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'Content-Type': 'text/vnd.turbo-stream.html',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); // Parse the response as text
      })
      .then((data) => {
        Turbo.renderStreamMessage(data);
      })
      .catch((error) => {
        console.error('Error fetching Turbo Stream data:', error);
      });
  }
}
