import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];
  footerVisible = false;

  connect() {
    this.lastScrollStop = window.scrollY;
    window.addEventListener('scroll', this.checkFooterScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener('scroll', this.checkFooterScroll.bind(this));
  }

  checkFooterScroll() {
    const delta = window.scrollY || document.documentElement.scrollTop;
    const activationPoint = 400; // 500px (hero height) - 100px (white sticky nav height)
    if (this.hasContentTarget) {
      if (delta > activationPoint && !this.footerVisible) {
        this.contentTarget.classList.remove('tw-hidden');
        this.footerVisible = true;
      } else if (delta <= activationPoint && this.footerVisible) {
        this.contentTarget.classList.add('tw-hidden');
        this.footerVisible = false;
      }
      this.lastScrollStop = delta;
    }
  }
}
