import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'icon'];

  toggle(event) {
    event.stopPropagation();
    this.contentTarget.classList.toggle('tw-hidden');
    if (this.hasIconTarget) {
      this.iconTarget.classList.toggle('fa-angle-down');
      this.iconTarget.classList.toggle('fa-angle-up');
    }
  }
}
