import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['overlay', 'message'];

  connect() {
    this.resolve = null;
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    document.addEventListener('click', this.handleButtonClick);
    this.overlayTarget.addEventListener('click', this.handleOverlayClick);
    this.hide();
  }

  disconnect() {
    document.removeEventListener('click', this.handleButtonClick);
    this.overlayTarget.removeEventListener('click', this.handleOverlayClick);
  }

  handleButtonClick(event) {
    const element = event.target.closest('[data-custom-confirm]');
    if (element) {
      this.show(event);
    }
  }

  handleOverlayClick(event) {
    if (event.target === this.overlayTarget) {
      this.hide();
    }
  }

  show(event) {
    event.preventDefault();
    const element = event.target.closest('[data-custom-confirm]');
    if (element) {
      const customConfirmText = element.dataset.customConfirm;
      this.messageTarget.textContent = customConfirmText;
    }
    this.overlayTarget.classList.remove('tw-hidden');
    this.overlayTarget.classList.add('tw-flex');
    this.resolve = () => {
      this.overlayTarget.classList.add('tw-hidden');
      this.overlayTarget.classList.remove('tw-flex');
      event.target.closest('form').submit();
    };
  }

  hide() {
    this.overlayTarget.classList.add('tw-hidden');
    this.overlayTarget.classList.remove('tw-flex');
    this.resolve = null;
  }

  confirm() {
    if (this.resolve) {
      this.resolve();
    }
  }
}
