import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal'];

  connect() {
    document.addEventListener('turbo:load', () => {
      this.openModal();
    });
  }

  openModal() {
    const modal = document.getElementById('modal-preview');
    if (modal) {
      modal.style.display = 'flex';
    }
  }

  closeModal(event) {
    event.preventDefault();

    const modal = document.getElementById('modal-preview');
    const backdrop = document.getElementById('modal-backdrop');

    if (modal) {
      modal.style.display = 'none';
    }

    if (backdrop) {
      backdrop.remove();
    }
  }
}
