import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tab'];
  static activeTabClasses = ['tw-text-appsearch-dark-blue', 'tw-font-bold', 'tw-underline', 'tw-underline-offset-4'];

  connect() {
    const currentTab = this.getCurrentTab();
    this.styleTab(currentTab);
  }

  getCurrentTab() {
    const tabName = new URL(window.location.href).searchParams.get('tab') || 'details';
    return this.tabTargets.find((tab) => tab.dataset.tab === tabName);
  }

  activate(event) {
    const clickedTab = event.currentTarget;
    this.setParam(clickedTab);
    this.styleTab(clickedTab);
  }

  setParam(tab) {
    const url = new URL(window.location);
    url.searchParams.set('tab', tab.dataset.tab);
    window.history.replaceState({}, '', url);
  }

  styleTab(tab) {
    if (tab) {
      this.tabTargets.forEach((tab) => {
        tab.classList.remove(...this.constructor.activeTabClasses);
      });
      tab.classList.add(...this.constructor.activeTabClasses);
      this.setParam(tab);
    } else {
      throw new Error('no tab element found with data-tab of ', tab.dataset.tab);
    }
  }
}
