import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];
  static activeFilterClasses = ['tw-text-appsearch-dark-blue', 'tw-underline', 'tw-underline-offset-4'];

  connect() {
    this.applyActiveFilterClasses();
  }

  filter(event) {
    const value = event.target.dataset.value;
    const url = new URL(window.location.href);
    url.searchParams.set('filters', value);
    this.updateActiveFilter(value);
    this.fetchAndRenderTurboStream(url);
  }

  applyActiveFilterClasses() {
    const currentFilter = this.getCurrentFilter();
    this.updateActiveFilter(currentFilter);
  }

  getCurrentFilter() {
    const url = new URL(window.location.href);
    return url.searchParams.get('filters') || this.getDefaultFilter();
  }

  getDefaultFilter() {
    return this.data.get('default') || 'all';
  }

  updateActiveFilter(value) {
    this.buttonTargets.forEach((button) => {
      if (button.dataset.value === value) {
        button.classList.add(...this.constructor.activeFilterClasses);
      } else {
        button.classList.remove(...this.constructor.activeFilterClasses);
      }
    });
  }

  fetchAndRenderTurboStream(url) {
    fetch(url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'text/vnd.turbo-stream.html',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => {
        console.error('Error fetching Turbo Stream data:', error);
      });
  }
}
