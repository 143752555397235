import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu'];

  toggle() {
    this.menuTarget.classList.toggle('tw-hidden');
  }

  closeMenu() {
    this.menuTarget.classList.add('tw-hidden');
  }

  openMenu() {
    this.menuTarget.classList.remove('tw-hidden');
  }
}
