<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-min-h-0">
    <div class="carousel--news__footer tw-w-full">
      <ol
        class="tw-flex tw-items-center tw-justify-center tw-text-center tw-text-base md:tw-justify-between tw-flex-wrap md:tw-flex-nowrap pagination"
      >
        <li v-for="(card, index) in cards" :key="card.id">
          <a :class="{ active: isActive(index) }" @click="skipTo(index)"></a>
        </li>
      </ol>
    </div>
    <div v-if="cards.length > 0" class="tw-flex tw-justify-center tw-w-full" @mouseover="pause" @mouseleave="resume">
      <a
        id="text"
        class="tw-rounded-bl tw-bg-primary-gradient tw-p-6 max-md:tw-h-64 md:tw-basis-1/3 tw-shrink tw-overflow-y-auto scrollbar"
        :href="cards[index].url"
      >
        <p class="md:tw-text-2xl tw-pb-6 tw-text-xl tw-text-primary tw-font-bold">
          {{ cards[index].subtitle }}
        </p>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="tw-text-primary max-md:tw-line-clamp-6 news-description" v-html="cards[index].description"></p>
      </a>
      <div class="tw-basis-2/3 tw-hidden md:tw-block">
        <img id="image" class="tw-h-auto tw-w-full tw-rounded-br" :src="cards[index]?.image_url" :alt="cards[index]?.image_description" />
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
  data() {
    return {
      autoscroll: false,
      index: 0,
      cards: [],
      textElement: null,
      imageElement: null,
      imageHeight: 0,
    };
  },
  mounted() {
    const carouselElement = document.getElementById('carousels');
    if (carouselElement !== null) {
      const dataElement = carouselElement.dataset.news;
      const cards = JSON.parse(dataElement);
      if (cards.length > 0) {
        this.cards = cards;
        this.autoscroll = true;
        this.scroll();
      }
    }

    this.$nextTick(() => {
      this.textElement = document.getElementById('text');
      this.imageElement = document.getElementById('image');
      this.imageElement.addEventListener('load', () => {
        this.resizeContent();
      });
    });
  },
  created() {
    window.addEventListener('resize', this.resizeHandler);
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    isActive(index) {
      if (index === this.index) {
        return true;
      }
    },
    next() {
      this.textElement = document.getElementById('text');
      this.imageElement = document.getElementById('image');
      this.resizeContent();
      if (this.index === this.cards.length - 1) {
        this.index = 0;
      } else {
        this.index++;
      }
    },
    scroll() {
      setInterval(() => {
        if (this.autoscroll) {
          this.next();
        }
      }, 5500);
    },
    pause() {
      this.autoscroll = false;
    },
    resume() {
      this.autoscroll = true;
    },
    skipTo(card) {
      this.index = card;
      this.textElement = document.getElementById('text');
      this.imageElement = document.getElementById('image');

      this.resizeContent();
    },
    resizeHandler() {
      this.textElement = document.getElementById('text');
      this.imageElement = document.getElementById('image');
      this.resizeContent();
    },
    resizeContent() {
      if (this.textElement && this.imageElement) {
        this.imageHeight = window.getComputedStyle(this.imageElement).getPropertyValue('height');
        this.textElement.style.height = this.imageHeight;
      }
    },
    formatDate(timestamp) {
      return format(new Date(timestamp), 'MMM dd, yyyy @ hh:mm a');
    },
  },
};
</script>
