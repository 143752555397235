import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectableItem', 'selectAll', 'selectedCount'];

  connect() {
    this.updateSelectedCount();
  }

  toggleSelection(event) {
    console.log(event.currentTarget.querySelector('input[type="checkbox"]'));
    const isCheckboxOrLabelClicked = event.target.type === 'checkbox' || event.target.tagName === 'LABEL';
    if (isCheckboxOrLabelClicked) {
      const checkbox = event.currentTarget.querySelector('input[type="checkbox"]');
      event.currentTarget.classList.toggle('selected', checkbox.checked);
      this.updateSelectedCount();
      if (!checkbox.checked) {
        this.selectAllTarget.checked = false;
      }
    }
  }

  selectAllItems() {
    const isChecked = this.selectAllTarget.checked;
    this.selectableItemTargets.forEach((el) => {
      el.classList.toggle('selected', isChecked);
      el.querySelector('input[type="checkbox"]').checked = isChecked;
    });
    this.updateSelectedCount();
  }

  updateSelectedCount() {
    const selectedCount = this.selectableItemTargets.filter((el) => el.classList.contains('selected')).length;
    if (selectedCount > 0) {
      this.selectedCountTarget.textContent = `${selectedCount} selected`;
    } else {
      this.selectedCountTarget.textContent = '';
    }
  }
}
